export interface Column {
  columnName: string;
  columnDescription: string;
  dataType: "String" | "Date" | "Number";
  mandatory: boolean;
  expectedFormat: string;
  sampleData: string;
}

export interface ValidationResult {
  isValid: boolean;
  message: string;
}

function isKindOfCamelCase(str: string): boolean {
  const camelCaseRegex = /^[a-zA-Z][a-zA-Z0-9]*$/;
  return camelCaseRegex.test(str);
}

export function validateColumns(columns: any): ValidationResult {
  const dataTypeAllowedValues: Array<Column["dataType"]> = ["String", "Date", "Number"];

  if (!Array.isArray(columns)) {
    return { isValid: false, message: "The JSON should be an array of objects." };
  }

  for (let i = 0; i < columns.length; i++) {
    const column = columns[i] as Partial<Column>;

    const requiredKeys: Array<keyof Column> = [
      "columnName",
      "columnDescription",
      "dataType",
      "mandatory",
      "expectedFormat",
      "sampleData",
    ];

    const missingKeys = requiredKeys.filter((key) => !(key in column));
    if (missingKeys.length > 0) {
      return {
        isValid: false,
        message: `Object at index ${i} is missing keys: ${missingKeys.join(", ")}.`,
      };
    }

    if (typeof column.columnName !== "string" || !isKindOfCamelCase(column.columnName.trim())) {
      return {
        isValid: false,
        message: `Invalid columnName at index ${i}. It must be a string without spaces.`,
      };
    }

    if (typeof column.columnDescription !== "string") {
      return {
        isValid: false,
        message: `Invalid columnDescription at index ${i}. It must be a string.`,
      };
    }

    if (!dataTypeAllowedValues.includes(column.dataType as Column["dataType"])) {
      return {
        isValid: false,
        message: `Invalid dataType at index ${i}. Allowed values are ${dataTypeAllowedValues.join(
          ", "
        )}.`,
      };
    }

    if (typeof column.mandatory !== "boolean") {
      return {
        isValid: false,
        message: `Invalid mandatory at index ${i}. It must be a boolean.`,
      };
    }

    if (typeof column.expectedFormat !== "string") {
      return {
        isValid: false,
        message: `Invalid expectedFormat at index ${i}. It must be a string.`,
      };
    }

    if (typeof column.sampleData !== "string") {
      return {
        isValid: false,
        message: `Invalid sampleData at index ${i}. It must be a string.`,
      };
    }
  }

  return { isValid: true, message: "The JSON is valid." };
}
