import React from "react";
import { useLocation } from "react-router-dom";

const Error = () => {
  const location = useLocation();
  const { message } = location.state || {};
  return (
    <div className="error-page-message">
      {message ? <span>ERROR: {message}</span> : <span>ERROR</span>}
    </div>
  );
};

export default Error;
