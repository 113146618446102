import api from "./api";
import { ISchema, ISchemaProperties } from "../domain/mappingTypes";
import { TransformationToAdd } from "../tools/schemaConversionTools";

export type addSchemaType = {
  name: string;
  extension: string;
  jqString: string;
  forwardDestinyPath: string;
  forwardDestinyType: "Api" | "Queue" | "Bucket";
  forwardDestinyRegion: string;
  tenantId: string;
  CsvSchema: ISchemaProperties;
  InputSchemaId: string;
  transformations: TransformationToAdd[];
  outputSchemaName: string;
  snapshot: string;
};

export type schemaResultType = {
  foragentId: string;
  forwardDestinyId: string;
  inputSchemaId: string;
  outInputSchemaId: string;
  schemaConvertionId: string;
  schemaOutputId: string;
  error: string;
};

export type PaginationType = {
  take: number;
  skip: number;
  sort: string;
  order: string;
};

export const saveSchemaWorkflow = async (
  params: addSchemaType
): Promise<schemaResultType | { error: string } | null> => {
  try {
    const endpointVerb = params.InputSchemaId ? "put" : "post";
    const endpointUrl = params.InputSchemaId ? `/Workflow/${params.InputSchemaId}` : "/Workflow";
    const { data } = await api[endpointVerb](endpointUrl, params);
    return data;
  } catch (error: any) {
    const errors = error?.response?.data?.errors || {};
    const firstErrorKey = Object.keys(errors)[0];
    const keysToCheck = ["Transformations", "Name", "InputSchemaId"];

    if (keysToCheck.some((key) => firstErrorKey?.includes(key))) {
      return {
        error: errors[firstErrorKey],
      };
    }

    return {
      error: "An unexpected error has occurred. Please contact the system administrators.",
    };
  }
};

export const getSchemasByTenantId = async (tenantId: string, search: string = "") => {
  const url = `/Tenants/${tenantId}/schemas?active=true&count=true&schemaName=${search}`;
  const { data } = await api.get(url);
  return data;
};

export const getSchemasBySchemaId = async (schemaId: string): Promise<ISchema> => {
  const { data } = await api.get(`/schemas/${schemaId}`);
  return data;
};

export const getTemplatesByTenantId = async (
  tenantId: string,
  pagination: PaginationType
): Promise<{ values: ISchema[]; count: number }> => {
  const { take, skip, sort, order } = pagination;
  let url = `/Tenants/${tenantId}/Schemas?take=${take}&skip=${skip}&active=true&latest=true&count=true`;
  if (sort && order) url += `&sortColumnName=${sort}&sortOrder=${order}`;
  const { data } = await api.get(url);
  return data;
};

export const deactivateTemplatesBySchemaId = async (SchemaId: string) => {
  return await api.put(`/Schemas/${SchemaId}/Status`, { active: false });
};
