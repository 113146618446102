export const formatDate = (date: string) => {
  const dateObj = new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  return dateObj.toLocaleDateString("en-US", options);
};

export const formatFileSize = (fileSize: number) => {
  const units = ["Bytes", "KB", "MB", "GB", "TB"];
  let index = 0;

  while (fileSize >= 1024 && index < units.length - 1) {
    fileSize /= 1024;
    index++;
  }

  return `${fileSize.toFixed(2)} ${units[index]}`;
};

export const getFileExtension = (filename: string) => {
  const regex = /(?:\.([^.]+))?$/;
  const match = filename.match(regex);
  return match && match[1] ? match[1] : null; // Retorna a extensão ou null se não houver
};

export const hasDuplicates = (data: string[]) => {
  const uniqueSet = new Set(data.map((c) => c.toString().toUpperCase()));
  return uniqueSet.size !== data.length;
};

export function convertPropsToQueryString(obj: any) {
  const queryString = Object.entries(obj)
    .filter(
      ([_, value]) =>
        typeof value === "string" || typeof value === "number" || typeof value === "boolean"
    )
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
    .join("&");

  return queryString;
}
