import {
  IAnswerMapping,
  IColunm,
  ICondition,
  IHeaders,
  IOperator,
  ISchemaProperties,
} from "../domain/mappingTypes";

export const convertionToJQ = (answers: IAnswerMapping[]) => {
  let result = {};
  answers.forEach((i) => {
    if (i.to) {
      const toWithoutAccent = i.to.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      //eslint-disable-next-line
      const regexCleanString = /(?:\W|(?<=['"])s)/g;
      let toSanitized = toWithoutAccent.replace(regexCleanString, "").toLocaleUpperCase();
      if (i.hasTransformation && !i.isCustomColumn)
        toSanitized += `_${i.from.columnName.toLocaleUpperCase()}`;
      result = { ...result, [i.from.columnName]: `.${toSanitized}` };
    }
  });

  let jqList = removeQuotesFromValues(result);

  return `[ .[] | {${jqList}} ]`;
};

export const removeQuotesFromValues = (jqMapping: any): string => {
  let jqResult = "";
  for (const [key, value] of Object.entries(jqMapping)) {
    let columnName = value as string;
    jqResult = jqResult.concat(`"${key}": ${columnName.toUpperCase()}, `);
  }
  return jqResult.slice(0, -2);
};

export const convertionToCSV = (
  separetor: string,
  hasHeader: boolean,
  headerRow: number,
  columns: Array<IHeaders>,
  fromTo: Array<IAnswerMapping>,
  dateFormat?: string
): ISchemaProperties => {
  const data = {
    columnSeperator: separetor,
    hasHeaderRow: hasHeader,
    dateFormatForExcel: dateFormat,
    headerRowPosition: headerRow,
    columns: columns.map((c) => {
      return {
        name: normalizeStringToCVariablePattern(c.name).toUpperCase(),
        isOptionalColumn: true,
        isDate: c.type === "DateTime",
      } as IColunm;
    }),
  } as ISchemaProperties;

  data.columns.forEach((column) => {
    const item = fromTo.find((x) => {
      const columnToReplace = (column.name || "").replace(/\s/g, "").toUpperCase();
      const xToReplace = (x.from.columnName || "").replace(/\s/g, "").toUpperCase();
      return xToReplace === columnToReplace;
    });

    const isRequired = item ? !item.isOptionalColumn : true;
    column.isOptionalColumn = !isRequired;

    if (item?.isDate) {
      column.dateFormat = item?.dateFormat;
    }
  });
  return data;
};

export type TransformationToAdd = {
  value: string;
  field: string;
  conditions: ConditionsToAdd[] | null;
};

export type ConditionsToAdd = {
  conditionLogicalOperator: number;
  dataValue: string;
  dataValueType: string;
  dataFieldName: string;
  executionSequence: number;
};

const createNewConditionToAddAbject = (condition: ICondition, order: number): ConditionsToAdd => {
  return {
    conditionLogicalOperator: condition.operator.value,
    dataValue:
      typeof condition.value === "string" ? condition.value.toLowerCase() : condition.value,
    dataFieldName: normalizeStringToCVariablePattern(condition.column).toUpperCase(),
    dataValueType:
      String(condition.operator.value) === "1" || String(condition.operator.value) === "2"
        ? ""
        : condition.type,
    executionSequence: order,
  };
};

export const convertionToTransformation = (answers: IAnswerMapping[]) => {
  let result = [] as TransformationToAdd[];

  answers.forEach((a) => {
    if (a.isDate) {
      const field = normalizeStringToCVariablePattern(a.to.toUpperCase());
      const value = `return System.DateTime.Parse(data.${field}).ToString("yyyy-MM-dd");`;
      result.push({ value, field } as TransformationToAdd);
    }

    if (!a.hasTransformation || a.isDate) return;
    a.transformations?.forEach((t) => {
      if (t.hasTransformation && a.to) {
        let field = normalizeStringToCVariablePattern(a.to.toUpperCase());
        if (!a.isCustomColumn) field += "_" + a.from.columnName.toLocaleUpperCase();
        let value = `return "${t.value}";`;

        if (t.replaceType === "byOperation") {
          const firstValue = normalizeStringToCVariablePattern(t.value.toUpperCase());
          const secondValue = normalizeStringToCVariablePattern(
            t.replaceValueComplement?.toUpperCase()!
          );
          value = `return System.Math.Round(Double.Parse(data.${firstValue}) ${t.replaceOperation} Double.Parse(data.${secondValue}),4);`;
        }

        if (t.replaceType === "byConcatenation") {
          const valuesParsed = t.concatenation?.values?.map((value, index) => {
            if (t.concatenation?.isInput![index]) {
              value = `"${value}"`;
            } else {
              const valueNormalized = normalizeStringToCVariablePattern(value.toUpperCase());
              value = `data.${valueNormalized}`;
            }
            return value;
          });
          value = `return string.Join('-', ${valuesParsed?.join(", ")});`;
        }

        if (t.replaceType === "byCopy") {
          const valueNormalized = normalizeStringToCVariablePattern(t.value.toUpperCase());
          value = `return data.${valueNormalized};`;
        }

        if (t.replaceType === "byFlipSignage") {
          const normalizedColumnName = normalizeStringToCVariablePattern(a.to.toUpperCase());
          if (t.flipSignageType === "flipSignage") {
            value = `return double.Parse(data.${normalizedColumnName}) * -1;`;
          }

          if (t.flipSignageType === "alwaysPositive") {
            value = `return Math.Abs(double.Parse(data.${normalizedColumnName}));`;
          }

          if (t.flipSignageType === "alwaysNegative") {
            value = `return Math.Abs(double.Parse(data.${normalizedColumnName})) * -1;`;
          }
        }

        let newTransformation = { value, field } as TransformationToAdd;

        if (t.conditions?.length > 0) {
          let newConditions: Array<any> = [];
          let bufferConditions = [] as ConditionsToAdd[];
          t.conditions?.forEach((c, indexC) => {
            if (indexC === 0) {
              bufferConditions.push(createNewConditionToAddAbject(c, indexC));
              return;
            }
            if (c.grouping === "Or") {
              newConditions.push(bufferConditions);
              bufferConditions = [];
              bufferConditions.push(createNewConditionToAddAbject(c, indexC));
            } else {
              bufferConditions.push(createNewConditionToAddAbject(c, indexC));
            }
          });
          newConditions.push(bufferConditions);
          newTransformation.conditions = newConditions;
        }
        result.push(newTransformation);
      }
    });
  });

  const uniqueArray = Array.from(new Set(result.map((item) => item.value))).map((value) =>
    result.find((item) => item.value === value)
  );

  return uniqueArray as TransformationToAdd[];
};

export const extractDataTypes = (objetos: IOperator[]): string[] => {
  const dataTypes = new Set<string>();

  objetos.forEach((objeto) => {
    objeto.dataTypeList.forEach((dataType) => {
      dataTypes.add(dataType);
    });
  });

  return Array.from(dataTypes);
};

export function normalizeStringToCVariablePattern(stringValue: string): string {
  const stringValueWithoutAccent = stringValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  // eslint-disable-next-line no-useless-escape
  const regexCleanString = /(?:\W|(?<=['"])s)/g;
  const normalizedString = stringValueWithoutAccent.replace(regexCleanString, "");
  return normalizedString;
}
