import React, { useEffect } from "react";

import { Navigate, Outlet, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { MappingContext } from "../context/mappingDataContext";
import { convertPropsToQueryString } from "../utils/string";

const ProtectedRoutes = (props: any) => {
  const { dataComponent, setData, customColumnsValidation } = MappingContext();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  let memoizedLocation: string;
  const possibleRouteProp = ["edit", "schemas"];

  const id = searchParams.get("tenantId") || props.tenantId;
  const featureFlag = searchParams.get("featureFlag") || props.featureFlag;
  const enableVatPlatformProcessing =
    searchParams.get("enableVatPlatformProcessing") || props.enableVatPlatformProcessing;

  if (props.tenantId && !dataComponent?.tenantId) {
    const { route, ...restProps } = props;
    const queryString = convertPropsToQueryString(restProps);
    memoizedLocation =
      route && possibleRouteProp.includes(route) ? `/${route}?${queryString}` : `/?${queryString}`;
  } else {
    memoizedLocation = location.pathname + location.search;
  }

  useEffect(() => {
    if (id) {
      setData((d) => {
        return {
          ...d,
          tenantId: id,
          featureFlag: featureFlag ?? "false",
          enableVatPlatformProcessing: enableVatPlatformProcessing ?? "false",
        };
      });
      localStorage.setItem("tenantId", id);
      navigate(memoizedLocation, { replace: true });
    }
  }, [featureFlag, enableVatPlatformProcessing, id, navigate, setData, memoizedLocation]);

  if (dataComponent.tenantId && customColumnsValidation.isValid) return <Outlet />;

  const errorMessage = !dataComponent.tenantId
    ? "The tenantId parameter is required. Make sure you are sending it."
    : `The following error was encountered in "Avalara Fields": ${customColumnsValidation.message} Please, correct the value or remove the property.`;

  return <Navigate to="/error" state={{ message: errorMessage }} replace />;
};

export default ProtectedRoutes;
