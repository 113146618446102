import * as React from "react";
import { Alert, Dialog } from "@avalara/skylab-react";
import { MappingContext } from "../../context/mappingDataContext";
import { useNavigate } from "react-router-dom";
import FadeInEffect from "../../ui/FadeInEffect/FadeInEffect";
import ScaleEffect from "../../ui/ScaleEffect/ScaleEffect";
import SlideDownEffect from "../../ui/SlideDownEffect/SlideDownEffect";
import { IHeaders } from "../../domain/mappingTypes";
import ValidateHeaderChanges from "../ValidateHeaderChanges/ValidadeHeaderChanges";
import { calculateColumnChanges } from "../../utils/schema";

interface IXlsDialogProps {
  showDialog: boolean;
  showConfirmHeadersXls: boolean;
  worksheetNames: string[];
  readHeaders: Partial<IHeaders>[];
  xlsHasError: boolean;
  uploadErrorMessage: string;
  setShowConfirmHeadersXls: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseXlsDialog: () => void;
}

const XlsDialog: React.FunctionComponent<IXlsDialogProps> = (props) => {
  const { setHeaders, dataComponent, isEditMode } = MappingContext();
  const navigate = useNavigate();

  const { removedColumns } = calculateColumnChanges(props.readHeaders);
  const hasColumnMissing = isEditMode && removedColumns.length > 0;

  const handleConfirm = () => {
    setHeaders(props.readHeaders as IHeaders[]);
    setTimeout(() => navigate("/mapping"), 100);
    props.onCloseXlsDialog();
  };

  const cancelButton = () => (
    <button
      className="secondary small"
      data-testid="button-cancel-id"
      onClick={() => {
        props.onCloseXlsDialog();
      }}
    >
      Cancel
    </button>
  );

  return (
    <Dialog
      id="confirmHeaders"
      onSDismiss={(e) => {
        if (e.detail.id === "confirmHeaders") props.onCloseXlsDialog();
      }}
      open={props.showDialog}
      className="overflow-visible"
      noScroll
    >
      <div slot="header" id="dialog-xls-title" data-testid="dialog-xls-separator-id">
        {props.showConfirmHeadersXls && (
          <FadeInEffect id="dialog-xls-header-1" showElement={props.showConfirmHeadersXls}>
            <div>Please confirm the found headers</div>
          </FadeInEffect>
        )}

        {!props.showConfirmHeadersXls && (
          <FadeInEffect id="dialog-xls-header-2" showElement={!props.showConfirmHeadersXls}>
            <div>Please check the sheets</div>
          </FadeInEffect>
        )}
      </div>
      <div slot="body" style={{ minWidth: "500px" }}>
        <SlideDownEffect id="dialogId-body-1" showElement={!props.showConfirmHeadersXls}>
          <>
            <div className="text-md font-normal">The following sheets were found on your file:</div>
            <div style={{ maxHeight: "195px", overflow: "auto" }}>
              <ul className="margin-left-lg margin-top-sm margin-bottom-sm font-italic font-light">
                {props.worksheetNames.map((worksheetName, index) => (
                  <li key={worksheetName} className={!index ? "text-sm-strong" : ""}>
                    {worksheetName}
                  </li>
                ))}
              </ul>
            </div>
            <div className="text-sm-strong">
              The system will assume{" "}
              <span className="text-blue-medium font-italic">"{props.worksheetNames[0]}"</span> as
              the data source
            </div>
          </>
        </SlideDownEffect>
        <ScaleEffect
          id="dialogId-body-2"
          showElement={props.showConfirmHeadersXls}
          initialWidth={460}
        >
          <>
            <div>
              You have selected header row{" "}
              <span style={{ fontWeight: "bolder" }}>
                <b> {dataComponent.headerRowPosition}</b>
              </span>
            </div>
            {props.xlsHasError ? (
              <div className="alert-fix-position margin-top-sm">
                <Alert status="error" noDismiss>
                  <div data-testid="user-message-upload-error" className="no-wrap-big-screen">
                    {props.uploadErrorMessage}
                  </div>
                </Alert>
              </div>
            ) : (
              <>
                <div className="headers-preview">
                  {!hasColumnMissing && (
                    <div className="no-wrap-big-screen">
                      Based on this selection the following column headings will be used during
                      mapping:
                    </div>
                  )}
                </div>
                <div className="headers-preview-itens-container">
                  {isEditMode && <ValidateHeaderChanges readHeaders={props.readHeaders} />}
                  {!hasColumnMissing &&
                    props.readHeaders.map((item, index) => (
                      <div className="itens" key={`${index}`}>
                        {item.name}
                      </div>
                    ))}
                </div>
              </>
            )}
          </>
        </ScaleEffect>
      </div>
      <div slot="footer">
        {!props.showConfirmHeadersXls && (
          <FadeInEffect id="dialog-xls-footer-1" showElement={!props.showConfirmHeadersXls}>
            <>
              {cancelButton()}
              <button
                className="primary small"
                data-testid="button-continue-id"
                disabled={dataComponent.schemaSeparator === ""}
                onClick={() => {
                  props.setShowConfirmHeadersXls(true);
                }}
              >
                Continue
              </button>
            </>
          </FadeInEffect>
        )}

        {props.showConfirmHeadersXls && (
          <FadeInEffect id="dialog-xls-footer-2" showElement={props.showConfirmHeadersXls}>
            <>
              {props.worksheetNames.length > 1 ? (
                <button
                  className="secondary small"
                  data-testid="button-cancel-id"
                  onClick={() => {
                    props.setShowConfirmHeadersXls(false);
                  }}
                >
                  Back
                </button>
              ) : (
                <>{cancelButton()}</>
              )}

              <button
                className="primary small"
                data-testid="button-save-id"
                disabled={
                  dataComponent.schemaSeparator === "" || props.xlsHasError || hasColumnMissing
                }
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </>
          </FadeInEffect>
        )}
      </div>
    </Dialog>
  );
};

export default XlsDialog;
