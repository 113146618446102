import React, { useEffect, useMemo } from "react";

import RoutesConfig from "./routes";
import { MappingContext } from "./context/mappingDataContext";
import { validateColumns } from "./utils/validateAvalaraFields";

const App = (props: any) => {
  const {
    disableCountryStep,
    customColumnsValidation,
    setDisableCountryStep,
    setCustomColumns,
    setCustomColumnsValidation,
  } = MappingContext();

  const memoizedAvalaraFields = useMemo(() => {
    return props.avalaraFields ? props.avalaraFields : null;
  }, [props.avalaraFields]);

  useEffect(() => {
    if (memoizedAvalaraFields && (!disableCountryStep || customColumnsValidation.isValid)) {
      const validateAvalaraFields = validateColumns(memoizedAvalaraFields);
      if (validateAvalaraFields.isValid) {
        setDisableCountryStep(true);
        setCustomColumns(memoizedAvalaraFields);
      } else {
        setCustomColumnsValidation(validateAvalaraFields);
      }
    }
  }, [
    memoizedAvalaraFields,
    disableCountryStep,
    customColumnsValidation.isValid,
    setDisableCountryStep,
    setCustomColumns,
    setCustomColumnsValidation,
  ]);

  return (
    <div style={{ maxWidth: "940px", padding: "20px", marginLeft: "auto", marginRight: "auto" }}>
      <RoutesConfig {...props} />
    </div>
  );
};

export default App;
