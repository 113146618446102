import React, { createContext, useContext, useEffect, useMemo, useState } from "react";

import uuid from "react-uuid";
import {
  IAnswerMapping,
  IData,
  IOperator,
  ITemplateProperties,
  IHeaders,
} from "../domain/mappingTypes";
import { GetOperators } from "../services/conditionService";
import { ValidationResult } from "../utils/validateAvalaraFields";

export type IProps = {
  headers: IHeaders[];
  setHeaders: React.Dispatch<React.SetStateAction<IHeaders[]>>;
  disableCountryStep: boolean;
  setDisableCountryStep: React.Dispatch<React.SetStateAction<boolean>>;
  customColumns: any[];
  setCustomColumns: React.Dispatch<React.SetStateAction<any[]>>;
  selectedCountries: string[];
  customColumnsValidation: ValidationResult;
  setCustomColumnsValidation: React.Dispatch<React.SetStateAction<ValidationResult>>;
  setSelectedCountries: React.Dispatch<React.SetStateAction<string[]>>;
  selectedTypes: string[];
  setSelectedTypes: React.Dispatch<React.SetStateAction<string[]>>;
  values: string[];
  setValues: React.Dispatch<React.SetStateAction<string[]>>;
  fromTo: IAnswerMapping[];
  setFromTo: React.Dispatch<React.SetStateAction<IAnswerMapping[]>>;
  fromToHistory: IAnswerMapping[];
  updateFromTo: (
    fromDataParam: ITemplateProperties[],
    fromToParam: IAnswerMapping[],
    fromToHistoryParam: IAnswerMapping[]
  ) => void;
  fromData: ITemplateProperties[];
  setFromData: React.Dispatch<React.SetStateAction<ITemplateProperties[]>>;
  dataComponent: IData;
  setData: React.Dispatch<React.SetStateAction<IData>>;
  operators: IOperator[];
  isEditMode: boolean;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  clearContext: () => void;
  populateEditMode: (data: any) => void;
};

export const MappingDataContext = createContext({} as IProps);

export function CreateMappingDataProvider(extraData: any): ({ children }: any) => any {
  return function MappingDataProvider({ children }: any) {
    const [headers, setHeaders] = useState([] as IHeaders[]);
    const [disableCountryStep, setDisableCountryStep] = useState(false as boolean);
    const [customColumns, setCustomColumns] = useState<any[]>([]);
    const [customColumnsValidation, setCustomColumnsValidation] = useState<ValidationResult>({
      isValid: true,
      message: "",
    });
    const [selectedCountries, setSelectedCountries] = useState([] as string[]);
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [values, setValues] = useState([] as string[]);
    const [fromTo, setFromTo] = useState([] as IAnswerMapping[]);
    const [fromToHistory, setFromToHistory] = useState([] as IAnswerMapping[]);
    const [fromData, setFromData] = useState([] as ITemplateProperties[]);
    const [data, setData] = useState({
      schemaSeparator: "",
      headerRowPosition: 1,
      ...extraData,
    } as IData);
    const [operators, setOperators] = useState([] as IOperator[]);
    const [isEditMode, setIsEditMode] = useState(false);

    const clearContext = () => {
      setHeaders([]);
      setSelectedCountries([]);
      setSelectedTypes([]);
      setValues([]);
      setFromTo([]);
      setFromToHistory([]);
      setFromData([]);
      setData((currentState) => ({
        schemaSeparator: "",
        headerRowPosition: 1,
        tenantId: currentState.tenantId,
        ...extraData,
      }));
      setIsEditMode(false);
      sessionStorage.removeItem("snapshot");
    };

    const populateEditMode = (snapshot: any) => {
      setHeaders(snapshot.headers || []);
      setSelectedCountries(snapshot.selectedCountries || []);
      setSelectedTypes(snapshot.selectedTypes || []);
      setValues(snapshot.values || []);
      setFromToHistory(snapshot.fromTo || []);
      setFromData(snapshot.fromData || []);
      setData((prevData) => ({ ...prevData, ...snapshot.dataComponent }));
      setOperators(snapshot.operators || []);
      setIsEditMode(true);
      sessionStorage.setItem("snapshot", JSON.stringify(snapshot));
    };

    const updateFromTo = (
      fromDataParam: ITemplateProperties[],
      fromToParam: IAnswerMapping[],
      fromToHistoryParam: IAnswerMapping[]
    ) => {
      const newFromTo = fromDataParam.map((item) => {
        const existingItem = [fromToParam, fromToHistoryParam]
          .flat()
          .find((fromToItem) => fromToItem.from.columnName === item.columnName);

        if (existingItem) return existingItem;

        return {
          from: item,
          id: uuid(),
          isDate: item.dataType.toLocaleLowerCase() === "date",
          isOptionalColumn: true,
          hasTransformation: false,
        } as IAnswerMapping;
      });

      const updatedHistory = fromToHistoryParam.map((historyItem) => {
        const matchingItem = fromToParam.find(
          (fromToItem) => fromToItem.from.columnName === historyItem.from.columnName
        );
        if (matchingItem && matchingItem !== historyItem) {
          return matchingItem;
        }
        return historyItem;
      });

      const filteredHistory = updatedHistory.filter(
        (historyItem) =>
          !newFromTo.some((newItem) => newItem.from.columnName === historyItem.from.columnName)
      );

      const newHistoryItems = fromToParam.filter(
        (fromToItem) =>
          !newFromTo.some((newItem) => newItem.from.columnName === fromToItem.from.columnName)
      );

      setFromToHistory([...filteredHistory, ...newHistoryItems]);
      setFromTo(newFromTo);
    };

    useEffect(() => {
      const getData = async () => {
        const data = await GetOperators();
        setOperators(data);
      };

      getData();
    }, []);

    const contextValue = useMemo(() => {
      return {
        headers,
        setHeaders,
        disableCountryStep,
        setDisableCountryStep,
        customColumns,
        setCustomColumns,
        customColumnsValidation,
        setCustomColumnsValidation,
        selectedCountries,
        setSelectedCountries,
        selectedTypes,
        setSelectedTypes,
        values,
        setValues,
        fromTo,
        setFromTo,
        fromToHistory,
        updateFromTo,
        fromData,
        setFromData,
        dataComponent: data,
        setData,
        operators,
        setIsEditMode,
        isEditMode,
        populateEditMode,
        clearContext,
      };
    }, [
      headers,
      disableCountryStep,
      customColumns,
      customColumnsValidation,
      selectedCountries,
      selectedTypes,
      values,
      fromTo,
      fromToHistory,
      fromData,
      data,
      operators,
      isEditMode,
    ]);

    return (
      <MappingDataContext.Provider value={contextValue}>{children}</MappingDataContext.Provider>
    );
  };
}

export const MappingDataProvider = CreateMappingDataProvider({});

export default MappingDataProvider;

export const MappingContext = () => useContext(MappingDataContext);
